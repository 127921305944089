import React from 'react';
import BackgroundImage from '../../assets/backgrounds/second-section-background.jpg';
import ImageOne from '../../assets/images/second-section-image-one.jpg';
import ImageTwo from '../../assets/images/second-section-image-two.jpg';
import ImageThree from '../../assets/images/second-section-image-three.jpg';
import ImageFour from '../../assets/images/second-section-image-four.jpg';
import ImageFive from '../../assets/images/second-section-image-five.png';
import ImageSix from '../../assets/images/second-section-image-six.jpg';
import ImageSeven from '../../assets/images/second-section-image-seven.jpg';
import ButtonPage from '../Button';
import { SecondSectionDiv, DivContent, Title, DivImages, Image } from './styled';

const Header: React.FC = () => {
  const imagesItems: { key: number; imageSrc: string }[] = [
    { key: 1, imageSrc: ImageOne },
    { key: 2, imageSrc: ImageTwo },
    { key: 3, imageSrc: ImageThree },
    { key: 4, imageSrc: ImageFour },
    { key: 5, imageSrc: ImageFive },
    { key: 6, imageSrc: ImageSix },
    { key: 7, imageSrc: ImageSeven },
  ];
 

  return (

    <SecondSectionDiv  backgroundImage = {BackgroundImage}>
      <DivContent>
      <Title>Várias versões de um eu</Title>
      <DivImages>
      {imagesItems.map((imageItem) => (
          <Image key={imageItem.key} src={imageItem.imageSrc}/>
        ))}
      </DivImages>
      <ButtonPage />
      </DivContent>
    </SecondSectionDiv>

  );
};


export default Header;
