import React,
{
  useEffect,
} from 'react';
import './App.css';

// import {
//   Provider,
// } from 'react-redux';

import * as PackageInfo from '../package.json';

import Routes from './navigation/routes/app.routes';

// import store from './store';



const App: React.FC = () => {
  const projectPackage = PackageInfo;

  useEffect(() => {
    console.log(`Di Sena Profile Version: ${projectPackage.version}`);
  }, []);



  return (
    // <Provider store={store}>
    <Routes />
    // </Provider>
  );
};



export default App;
