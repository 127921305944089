import React from 'react';
import ImageOne from '../../assets/images/fourth-section-image-one.jpg';
import ImageTwo from '../../assets/images/fourth-section-image-two.jpg';
import ImageThree from '../../assets/images/fourth-section-image-three.jpg';
import ImageFour from '../../assets/images/fourth-section-image-four.jpg';
import ImageFive from '../../assets/images/fourth-section-image-five.jpg';
import { FourthSectionDiv, Title, Subtitle, DivContent, DivPhotos, PhotoDiv, DivPhotosDivided, Photo, PhotoLegend } from './styled';
const FourthSection: React.FC = () => {
  return (
    <FourthSectionDiv>
      <Title>Photos</Title>
      <Subtitle>Lorem Ipsum</Subtitle>
      <DivContent>
       <DivPhotos>
          <PhotoDiv><Photo src={ImageOne} $width={'300px'} $height={'450px'}/><PhotoLegend>#Meta gela</PhotoLegend></PhotoDiv>
          <PhotoDiv><Photo src={ImageTwo} $width={'350px'} $height={'550px'} /><PhotoLegend>#Riri fragrance launch</PhotoLegend></PhotoDiv>
          <DivPhotosDivided>
          <PhotoDiv><Photo src={ImageThree} $width={'300px'} $height={'270px'} /><PhotoLegend>#family</PhotoLegend></PhotoDiv>
          <PhotoDiv><Photo src={ImageFour} $width={'300px'} $height={'270px'} /><PhotoLegend>#Brit awards</PhotoLegend></PhotoDiv>
          </DivPhotosDivided>
          <PhotoDiv><Photo src={ImageFive} $width={'300px'} $height={'450px'} /><PhotoLegend>#Antiversary</PhotoLegend></PhotoDiv>
       </DivPhotos>
      </DivContent>
    </FourthSectionDiv>

  );
};


export default FourthSection;
