import React, { useState } from 'react';
import LogoImage from '../../assets/images/logo.png';
import ImageFlag from '../../assets/images/flag.png';
import ImagePlay from '../../assets/images/play.png';
import { GiHamburgerMenu } from 'react-icons/gi';

import {
  NavDiv,
  Logo,
  NavBarMenuContent,
  NavBarMenuNotMobile,
  MenuItems,
  MenuItem,
  ImagesDiv,
  Image,
  NavBarMenuMobile,
  NavBarMenuButtonMobile,
  NavBarItemsMobile,

} from './styled';

const Header: React.FC = () => {
  const menuItems: { key: number; name: string }[] = [
    { key: 1, name: 'home' },
    { key: 2, name: 'about' },
    { key: 3, name: 'agenda' },
    { key: 4, name: 'projects' },
    { key: 5, name: 'photos' },
    { key: 6, name: 'music' },
    { key: 7, name: 'videos' },
    { key: 8, name: 'promotions' },
    { key: 9, name: 'shop' },
    { key: 10, name: 'contact' },
  ];
  const activeStyle = { color: '#a33881' };
  const [menuItemSelected, setMenuItemSelected] = useState<any>(undefined);
  function selectMenu() {
    setMenuItemSelected('');
  }

  const [show, setShow] = useState(false);

  const toggleMenu = () => {
    setShow(!show);
  };

  return (
    <NavDiv>
  
      <NavBarMenuContent>
        <NavBarMenuNotMobile>
        <Logo src={LogoImage} />
        <MenuItems>
          {menuItems.map((menuItem) => (
            // menuItemSelected== menuItem.key ? color: '#a33881' : color: 'white' }
            <MenuItem
              key={menuItem.key}
              style={menuItemSelected === menuItem.key ? activeStyle : {}}
              onClick={() => setMenuItemSelected(menuItem.key)}
            >
              {menuItem.name}
            </MenuItem>
          ))}
</MenuItems>
          <ImagesDiv>
            <Image src={ImageFlag} />
            <Image src={ImagePlay} />
          </ImagesDiv>
        </NavBarMenuNotMobile>

        <NavBarMenuMobile  onClick={toggleMenu} >

 <NavBarMenuButtonMobile >
              <GiHamburgerMenu style={ { zIndex:'1001' } } />
              </NavBarMenuButtonMobile>

<NavBarItemsMobile show={show} >
{menuItems.map((items: any) => (
              <p>
                        {String(items.name).toUpperCase()}
                        </p>   
                  ))}   
          </NavBarItemsMobile >
 </NavBarMenuMobile>

      </NavBarMenuContent>
    </NavDiv>
  );
};

/*
      <NavBarContainer
    

        <NavBarContent>


          <NavBarMenuNotMobile>
          <NavBarItemsNotMobile >
              {props.navItems && (
                <NavBarCollapseNotMobile id={'navbar-buttons'}>

                  {props.navItems.map((items: any) => (
                    <NavBarItemNotMobile
                      href={items.navHref}>
                      <NavBarButtonNotMobile>
                        {String(items.navTitle).toUpperCase()}
                      </NavBarButtonNotMobile>
                    </NavBarItemNotMobile>
                  ))}

                </NavBarCollapseNotMobile>
              )}
               </NavBarItemsNotMobile>  
            </NavBarMenuNotMobile>
 <NavBarMenuMobile  onClick={toggleMenu} >

 <NavBarMenuButtonMobile>
              <GiHamburgerMenu style={ { zIndex:'1001' } } />
              </NavBarMenuButtonMobile>

<NavBarItemsMobile  show={show} >
{props.navItems.map((items: any) => (
                    <NavBarItemMobile
                      href={items.navHref}>
                      <NavBarButtonMobile>
                        {String(items.navTitle).toUpperCase()}
                      </NavBarButtonMobile>
                    </NavBarItemMobile>
                  ))}   
          </NavBarItemsMobile >
 </NavBarMenuMobile>

        </NavBarContent>
      </NavBarContainer>
*/
export default Header;
