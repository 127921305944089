import React from 'react';

import { ImageBorder,ImageBig } from './styled';

interface IBigImageProps{
   srcImage: string;
  }
  
    const BigImage: React.FC<IBigImageProps> = (props): JSX.Element => {
  return (


<ImageBorder>
                <ImageBig src={props.srcImage}/>
            </ImageBorder>


  );
};


export default BigImage;
