export const FILES_DOWNLOAD = {
  logosDownloadLink:                'https://download.club',
  iconDownloadLink:                 'https://download.club',
  screensDownloadLink:              'https://download.club',
  imagesDownloadLink:               'https://download.club',
};


export const NOITADA_SOCIAL = {
  facebookUsername:                 'noitadaBRA',
  facebookIDUsername:               '636260263204734',
  facebookLink:                     'https://www.facebook.com/noitadaBRA',

  instagramUsername:                'noitadaBRA',
  instagramLink:                    'https://www.instagram.com/_u/noitadaBRA',
  
  twitterUsername:                  'noitadaBRA',
  twitterIDUsername:                '837020906467766272',
  twitterLink:                      'https://www.twitter.com/noitadaBRA',

  linkedInLink:                     'https://www.linkedin.com/company/noitada',

  youtubeChannel:                   'UCymPVkRbb1sj2UPQs_YVqGw',
  youtubeLink:                      'https://www.youtube.com/noitada',

  noitadaEmailContact:              'mailto:contact@noitada.net',
};


export const PERSAS_SOCIAL = {
  persasSiteLink:                   'https://persas.club',
  persasInstagramLink:              'https://www.instagram.com/_u/persas.club',
};


export const STORES = {
  noitadaMobileAppAndroidLink:      'https://play.google.com/store/apps/details?id=com.noitada',
  noitadaMobileAppIosLink:          'https://apps.apple.com/us/app/noitada/id1455081329',
  
  organizerMobileAppAndroidLink:    'https://play.google.com/store/apps/details?id=com.organizer',
  organizerMobileAppIosLink:        'https://apps.apple.com/us/app/noitada/id1445211329',
};



const Keys = {
  ...FILES_DOWNLOAD,
  ...NOITADA_SOCIAL,
  ...PERSAS_SOCIAL,
  ...STORES,
};



export default Keys;
