import React from 'react';
import BackgroundImage from '../../assets/backgrounds/second-section-background.jpg';
import ImageOne from '../../assets/images/second-section-image-one.jpg';
import ImageTwo from '../../assets/images/second-section-image-two.jpg';
import ImageThree from '../../assets/images/second-section-image-three.jpg';
import ImageFour from '../../assets/images/second-section-image-four.jpg';
import ImageFive from '../../assets/images/second-section-image-five.png';
import ImageSix from '../../assets/images/second-section-image-six.jpg';
import ImageSeven from '../../assets/images/second-section-image-seven.jpg';
import { Button } from './styled';


interface IButtonProps{
    width?: string,
    backgroundColor?: string,
    fontColor?: string,
    borderColor?: string,
    borderRadius?: string,
    buttonText?: string,
  }
    const ButtonPage: React.FC<IButtonProps> = ({ width = '150px', backgroundColor= '#af00dd', fontColor='#FFF', borderColor='transparent', borderRadius='30px',
    buttonText='Song Collection' }): JSX.Element => {
  return (

<Button $width={width} $backgroundColor={backgroundColor} $fontColor={fontColor} $borderColor={borderColor}  $borderRadius={borderRadius}>
    {buttonText}
</Button>

  );
};


export default ButtonPage;
