import styled from 'styled-components';

interface IProps {
  transparent?: boolean;
}

export const FirstSectionDiv = styled.div`
background-color: #000;
display: flex;
justify-content: center;
padding-top:5%;
padding-bottom:5%;
`;

export const ContentDiv = styled.div`
display: flex;
justify-content: space-between;
width: 80%;
@media(max-width: 975px){
  width: 90%;
}
@media(max-width: 760px){
  width: 95%;
}
@media(max-width: 720px){
flex-direction: column;
align-items: space-between;
}
`;

export const TitlesAndMediasDiv = styled.div`
display: flex;
flex-direction: column;


`;

export const TitlesDiv = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
`;

export const Title = styled.h1`
color: #e6b732;
font-size: 7.5rem;
font-weight: bolder;
`;

export const Subtitle = styled.h2`
color: #e6b732;
font-size: 4rem;
font-weight: bolder;
`;

export const MediasDiv = styled.div`
display: grid;

grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-row-gap: 5%;
grid-column-gap: 10%;
margin-top:10%;
@media(max-width: 720px){
  grid-row-gap: 25%;
  grid-column-gap: 5%;
}
@media(max-width: 500px){
  grid-row-gap: 10%;
  grid-column-gap: 5%;
}
`;

export const DivMedia = styled.div`
margin-top:15%;
display: flex;
flex-direction: column;
@media(max-width: 720px){
  margin-top:0%;
}

`;

export const MediaTitle = styled.h1`
color: #FFF;
font-size: 1.6rem;
text-transform: uppercase;
font-weight: bold;

`;

export const MediaImages = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
`;


export const DivSmallLogo = styled.div`
display: flex;
flex-direction: row;
margin-top: 30px;
margin-left: 5%;
align-items:center;

`;

export const MediaText = styled.p`
font-size: 1.6rem;
margin-left: 5%;
color: white;

`;


export const DivBigImage = styled.div`

width: 30%;
@media(max-width:1470px){
  width:35%;
  }  

  @media(max-width:1470px){
    width:32%;
    }  
  @media(max-width:1335px){
    width:33%;
    }
    
    @media(max-width:1035px){
      width:36%;
    }    
      
    @media(max-width:835px){
      width:40%;
    }   
    @media(max-width:785px){
      width:100%;
    }       
    @media(max-width: 720px){
     margin-top: 14%;
     display: flex;
     justify-content: center;
      }
      @media(max-width: 500px){
        margin-top: 14%;
      }
   
`;
