import React from 'react';
import BackgroundImage from '../../assets/backgrounds/second-section-background.jpg';
import ButtonPage from '../Button';
import { SlLocationPin } from 'react-icons/sl';

import { EventItemDiv, Circle, Date, InfosDiv, Title, Location, LocationText } from './styled';


  interface IEventItemProps{
    date:string,
    title: string,
    location: string,
    buttonText: string,
    buttonBackgroundColor: string,
    buttonBorderColor: string,
  }


  const EventItem: React.FC<IEventItemProps> = (props): JSX.Element => {   
    return (

    <EventItemDiv>
    <Circle>
        <Date>
     {props.date}
        </Date>
    </Circle>
    <InfosDiv>
        <Title>{props.title}</Title>
        <Location><SlLocationPin color={'#b01694'}/><LocationText>{props.location}</LocationText></Location>
      <ButtonPage width={'120px'} buttonText={props.buttonText} backgroundColor={props.buttonBackgroundColor} borderColor={props.buttonBorderColor} />
    </InfosDiv>
    </EventItemDiv>

  );
};


export default EventItem;
