import React from 'react';

import { SixthSectionDiv, DivContent, DivPhotos, Photo } from './styled';
import ImageOne from '../../assets/images/second-section-image-one.jpg';
import ImageTwo from '../../assets/images/second-section-image-two.jpg';
import ImageThree from '../../assets/images/second-section-image-three.jpg';
import ImageFour from '../../assets/images/second-section-image-four.jpg';
import ImageFive from '../../assets/images/second-section-image-five.png';


const SixthSection: React.FC = () => {
  return (

    <SixthSectionDiv>
     <DivContent>
       <DivPhotos>
         <Photo src={ImageOne} />
          <Photo src={ImageTwo} />
          <Photo src={ImageThree} />
          <Photo src={ImageFour} />
          <Photo src={ImageFive} />
       </DivPhotos>
      </DivContent>
    </SixthSectionDiv>

  );
};


export default SixthSection;
