import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../common/constants';

import {
  Screen,
} from '../Composh';



interface IProps {
  transparent?: boolean;
}


export const EventItemDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 20px;
`;

export const Circle = styled.div`
border: 1px solid #FFF;
border-radius: 100%;
display: flex;
width:60px;
height: 60px;
padding:1%;
flex-direction: row;
justify-content: center;
align-items: center;
text-align: center;

`;

export const Date = styled.p`
color: white;
font-size: 1.2rem;
`;

export const InfosDiv = styled.div`
display: flex;
flex-direction: column;
gap: 10px
`;

export const Title = styled.h1`
color: white;
font-size: 2rem;
`;

export const Location = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
justify-content: center;
gap:3px;
`;


export const LocationText = styled.p`
color: #b01694;
font-size: 1.5rem;
`;
