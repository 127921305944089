import React from 'react';
import EventItem from '../EventItem';
import BigImage from '../BigImage';
import ThirdSectionImage from '../../assets/images/third-section-image.jpg';
import { ThirdSectionDiv,Title, DivContent, DivEventItems } from './styled';

const ThirdSection: React.FC = () => {
  const eventItems: { key: number; date: string, title: string, location: string, buttonText: string, buttonBackgroundColor: string, buttonBorderColor: string }[] = [
    { key: 1, date: '13, Nov 2018', title: 'Coachella Music Festival', location: '29, Whesthemer Rd Santa Ana', buttonText: 'Buy tickets', buttonBackgroundColor: '#af00dd', buttonBorderColor: 'transparent' },
    { key: 2, date: '13, Nov 2018',  title: 'Coachella Music Festival', location: '29, Whesthemer Rd Santa Ana', buttonText: 'Buy tickets', buttonBackgroundColor: 'transparent', buttonBorderColor: '#af00dd' },
    { key: 3, date:  '13, Nov 2018',  title: 'Coachella Music Festival', location: '29, Whesthemer Rd Santa Ana', buttonText: 'Buy tickets', buttonBackgroundColor:  'transparent', buttonBorderColor: '#af00dd' } ,
    { key: 4, date:  '13, Nov 2018',  title: 'Coachella Music Festival',  location: '29, Whesthemer Rd Santa Ana', buttonText: 'Buy tickets', buttonBackgroundColor: 'transparent', buttonBorderColor: '#af00dd' },
  ];
  return (

    <ThirdSectionDiv>
      <Title>Upcoming Events</Title>
      <DivContent>
      <DivEventItems>
      {eventItems.map((eventItem) => (
          <EventItem key={eventItem.key} date={eventItem.date} title={eventItem.title} location={eventItem.location} buttonText={eventItem.buttonText}
          buttonBackgroundColor={eventItem.buttonBackgroundColor} buttonBorderColor={eventItem.buttonBorderColor} />
        ))}
      </DivEventItems>
      <BigImage srcImage={ThirdSectionImage} />
      </DivContent>
    </ThirdSectionDiv>

  );
};


export default ThirdSection;
