import React from 'react';
import FirstSection from '../../components/FirstSection';
import Header from '../../components/Header';
import SecondSection from '../../components/SecondSection';
import ThirdSection from '../../components/ThirdSection';
import FourthSection from '../../components/FourthSection';
import FifthSection from '../../components/FifthSection';
import SixthSection from '../../components/SixthSection';
import Footer from '../../components/Footer';
import GlobalStyle from '../../components/theme/GlobalStyle';

const HomeScreen: React.FC = () => {
  return (
<>
<GlobalStyle />
    <>     
     <Header />
     <FirstSection />
     <SecondSection />
     <ThirdSection />
     <FourthSection />
     <FifthSection />
     <SixthSection />
     <Footer />
    </>
    </>

  );
};


export default HomeScreen;
