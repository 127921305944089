export const APP_ROUTES = {
  WaitingScreen:                    '/',
  HomeScreen:                       '/home',
};



const NameRoutes = {
  ...APP_ROUTES,
};



export default NameRoutes;
