import React from 'react';
import LogoImage from '../../assets/images/logo.png';
import { AiFillInstagram } from 'react-icons/ai';
import {  BsLinkedin } from 'react-icons/bs';
import { FaGoogle, FaYoutube } from 'react-icons/fa';
import { FooterDiv, DivContent, PageDescriptionDiv, Logo, Description, SocialMedias,
  LinksDiv, QuickLinksDiv, TitleLinks, TextLinks, SocialContactDiv, SideLinksDiv, ButtonSideLinks  } from './styled';
const Footer: React.FC = () => {
  return (
    <FooterDiv>
      <DivContent>
      <PageDescriptionDiv>
        <Logo src={LogoImage}/>
        <Description>
          Making A first home purchase can be intimidating and stressful. Tracy s approach to service gave us
          the confidence that purchasing can bne intimidating and stressful.  Tracy s approach to service gave us
          the confidence that.
        </Description>
        <SocialMedias>
          <FaGoogle color={'#c000d5'} size={'2.1rem'}/>
          < AiFillInstagram color={'#c000d5'} size={'2.6rem'} />
          <BsLinkedin color={'#c000d5'} size={'2.1rem'} />
          <FaYoutube color={'#c000d5'} size={'2.9rem'} />
        </SocialMedias>
      </PageDescriptionDiv>
      
      <LinksDiv>
      
      <QuickLinksDiv>
      <TitleLinks>Quick Links</TitleLinks>
      <TextLinks>About us</TextLinks>
      <TextLinks>Biography</TextLinks>
      <TextLinks>Video</TextLinks>
      <TextLinks>Shop</TextLinks>
      </QuickLinksDiv>

      <SocialContactDiv>
      <TitleLinks>Social Contact</TitleLinks>
      <TextLinks>Services</TextLinks>
      <TextLinks>Help</TextLinks>
      <TextLinks>FAQ</TextLinks>
      <TextLinks>Support</TextLinks>
      </SocialContactDiv>

      </LinksDiv>

      <SideLinksDiv>
       <ButtonSideLinks>Links de Patreon</ButtonSideLinks>
       <ButtonSideLinks>Produção</ButtonSideLinks>
      </SideLinksDiv>
      </DivContent>
    </FooterDiv>

  );
};


export default Footer;
