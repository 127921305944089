import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../common/constants';

import {
  Screen,
} from '../Composh';



interface IProps {
  transparent?: boolean;
}


export const FourthSectionDiv = styled.div`
background-color: rgba(37, 20, 33,0.9);
margin-top: 2%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const  DivContent = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 80%;
justify-content: center;
`;

export const  Title = styled.h1`
color: white;
font-size: 3rem;
margin-top: 1%;
font-weight: bold;
`;


export const Subtitle= styled.h2`
color: gray;
font-size: 1.5rem;
margin-bottom: 3.5%;
margin-top:1%;
font-weight: bold;
`;

export const  DivPhotos = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 100%;
justify-content: space-between;
`;

export const  DivPhotosDivided = styled.div`
display: flex;
flex-direction: column;
gap: 10px
`;


export const  PhotoDiv = styled.div`
/*
display: flex;
justify-content: center;
*/
`;

export const  Photo = styled.img<{ $width?: string, $height?:string }>`
width: ${(props) => props.$width};
height: ${(props) => props.$height};
 filter: blur(0.7px);
`;

export const  PhotoLegend = styled.p`

`;

