import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../common/constants';

import {
  Screen,
} from '../Composh';



interface IProps {
  transparent?: boolean;
}


export const FooterDiv = styled.div`
background-color: rgba(37, 20, 33,0.9);
margin-top: 2%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const  DivContent = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 80%;
justify-content: space-between;
`;

export const  PageDescriptionDiv = styled.div`
width: 25%;
`;

export const Logo = styled.img`
width: 25%;
margin-top: 5%;
margin-bottom: 5%;
`;

export const Description = styled.p`

`;

export const SocialMedias = styled.div`
display: flex;
width: 40%;
margin-top: 3%;
justify-content: space-evenly;
align-items: center;
`;


export const LinksDiv = styled.div`
width: 35%;
display: flex;
justify-content: space-evenly;
`;


export const QuickLinksDiv = styled.div`

`;

export const TitleLinks = styled.h1`
font-size: 2.1rem;
margin-bottom: 15%;
color: white;
font-weight: bold;
`;

export const TextLinks = styled.p`

`;

export const SocialContactDiv = styled.div`

`;

export const SideLinksDiv = styled.div`
background-color: black;
padding: 2%;
width: 16%;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: space-between;
`;

export const ButtonSideLinks = styled.p`
background-color: #4c1355;
margin-bottom: 15%;
padding: 5%;
color: white;
display: flex;
justify-content: center;
align-items: center;
`;



