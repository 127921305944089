export const APPCOLORS = {
  primary:                          '#009688',
  primaryDark:                      '#09403C',
  secondary:                        '#2BDA8E',
  tertiary:                         '#FFE358',
  accent:                           '#00FFFF',
  danger:                           '#F51E1D',
  attention:                        '#FF7D35',
  accept:                           '#20DC62',
};


export const APPDEFAULT = {
  black:                            '#000000',
  white:                            '#FFFFFF',
  gray:                             '#898989',
  muted:                            '#9FA5AA',
  transparent:                      'transparent',
  neutral:                          'rgba(255,255,255, 0.65)',
};


export const APPITEMS = {
  statusBar:                        'darkred', // '#0B0F23',
  appBackground:                    '#010214',
  helpBackground:                   '#f5f5f5',
};


export const BUTTONS = {
  buttonOkDark:                     '#1B7272',
  buttonOk:                         '#11a7a1',
  buttonOkAccent:                   '#17d6cc',
  buttonCancel:                     '#501111',
  buttonCancelAccent:               '#822020',
  buttonDisabled:                   '#84878E',
  buttonOpacity:                    'rgba(38, 111, 139, 0.3)',
  buttonDisabledOpacity:            'rgba(64, 64, 64, 0.5)',
};


export const CARD = {
  cardBackground:                   '#1d233a',
  cardOverlay:                      'rgba(0, 0, 0, 0.7)',
};


export const TEXT = {
  textTitle:                        '#FFFFFF',
  textSubtitle:                     '#FFFFFF',
  textDescription:                  '#c7c7c7',
  textSubDescription:               '#9496A0',
  textApp:                          '#878787',
};


export const VIEWS = {
  linearTopPrimary:                 '#006464',
  linearBottomPrimary:              '#00b0b0',

  linearTopApp:                     '#2D3149',
  linearBottomApp:                  '#141934',
};



const Colors = {
  ...APPCOLORS,
  ...APPDEFAULT,
  ...APPITEMS,
  ...BUTTONS,
  ...CARD,
  ...TEXT,
  ...VIEWS,
};



export default Colors;
