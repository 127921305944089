import React from 'react';
import Nav from '../Nav';
import { HeaderDiv, TitlesDiv, Title, FirstSubtitle, SecondSubtitle, NameAndUserNameDiv, Name, Surname } from './styled';

const Header: React.FC = () => {
  return (

    <HeaderDiv>
      <Nav />
      <TitlesDiv>
    <Title>Music</Title>
    <FirstSubtitle>
      Speaks Louder <br />Than Words</FirstSubtitle>
    <SecondSubtitle>We add the cerebral and cool to instrumental  music. </SecondSubtitle>
    </TitlesDiv>
    <NameAndUserNameDiv>
    <Name>Nome</Name>
    <Surname>Sobrenome</Surname>
    </NameAndUserNameDiv>
    </HeaderDiv>

  );
};


export default Header;
