const OTHERS = {

  heightToolbar:                    70,

  iconAndroidSize:                  24,
  iconSocialTreePages:              24,
  iconTermsPages:                   16,

  contentLandingMinHeight:          500,
  contentLandingPadding:            125,

  cardRadius:                       '5px',
  cardBorderLine:                   0.5,

};



export const FONTS = {        
  sectionTitle:                   60,
  sectionText:                    22,

  input:                          16,
  regular:                        14,
  medium:                         12,
  small:                          11,
  tiny:                           10,
};


export const MARGINS = {        
  paddingVertical:                '5rem',
  paddingHorizontal:              '6rem',
  marginFromTitles:               '4rem',
};


export const TOOLBARS = {        
  toolbarHeight:                  '75px',
};



const Sizes = {
  ...FONTS,
  ...MARGINS,
  ...OTHERS,
  ...TOOLBARS,
};



export default Sizes;
