import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../common/constants';

import {
  Screen,
} from '../Composh';



interface IProps {
  transparent?: boolean;
  show?: boolean;
}


export const NavDiv = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 2%;


`;

export const Logo= styled.img`
width:10%;
margin-right: 2%;
background-color: #000;

@media (max-width:920px) {
    width:10%;
}
@media (max-width:750px) {
      width:12%;
}
@media (max-width:650px) {
        width:15%;
}
@media (max-width:595px) {
          width:18%;
}
@media (max-width:380px) {
            width:20%;
}
`;

export const NavBarMenuContent =  styled.div`
display: flex;
flex-direction: row;
flex: 1;
align-items: center;
justify-content: center;
padding-top: 0px;

padding-bottom: 0px;
   
`;

export const NavBarMenuNotMobile = styled.div`

display: flex;
justify-content: space-evenly;
align-items: center;
@media(min-width: 885px){
  width: 95%;
}
@media(min-width: 1320px){
  width: 90%;
}
@media(min-width: 1400px){
  width: 80%;
}
@media(max-width: 575px){
  display:none;
}
`;

export const  MenuItems= styled.div`
display: flex;
width: 100%;
justify-content: space-between;
@media (max-width: 1150px){
  justify-content: center;
  flex-wrap: wrap;
}
`;
export const MenuItem= styled.a`
color: white;
font-weight: bold;

&:hover {
  color: '#a33881';
}
@media (max-width: 1150px){
margin-left: 3%;
margin-right: 3%;
margin-bottom: 1%;
}


font-size: 1.9rem;
@media (max-width: 1260px){
  font-size: 1.6rem;
}

`;

export const ImagesDiv= styled.div`
width: 10%;
margin-left: 2%;
display: flex;
justify-content: space-around;
`;

export const Image= styled.img`
width: 20%;
height: 70%;
@media (max-width: 1180px) {
width: 30%;
}
@media (max-width: 920px) {
width: 35%;
}
@media (max-width: 865px){
  width: 40%;
}
@media (max-width: 800px){
  width: 45%;
}
@media (max-width: 760px){
  width: 50%;
}
@media (max-width: 720px){
  width: 55%;
}
@media (max-width: 665px){
  width: 60%;
}
@media (max-width: 620px){
  width: 65%;
}
@media (max-width: 540px){
  width: 75%;
}
`;

export const NavBarMenuMobile = styled.div`
position: absolute;
display:  flex
margin-top: 0.5em;
padding: 2px;
margin-top: 8%;
font-size: 1.5em;
font-family: "Yanone Kaffeesatz", sans-serif;
z-index: 1000;
right: 4%;
@media(min-width: 575px){
  display:none;
}
`;



export const NavBarMenuButtonMobile = styled.p`
color: ${Colors.white};
letter-spacing: 2px;
font-size: 25px;
text-align: center;
pointer-events: none;
display: flex;
flex-direction: row;
align-items: center;


`;

export const NavBarButtonMobile = styled.p`
color: ${Colors.white};
letter-spacing: 2px;
font-size: 15px;
pointer-events: none;

transition: all 0.20s;
`;

export const NavBarPaddingTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Sizes.toolbarHeight};
`;

export const NavBarItemsMobile = styled.div`
width: 100vw;
height: 100vh;
padding-top: 6vh;
padding-right: 2vh;
bottom:0; 
display:  ${(props: IProps) => props.show ? 'flex' : 'none'};
position: fixed;
flex-direction:column;
text-align: end;
align-items: flex-end;
transition: 550ms;
background-color: rgb(0,0,0, 0.90);
right: 0;
transition: 350ms;
overflow: hidden;
`;
