import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../common/constants';

import {
  Screen,
} from '../Composh';



interface IProps {
  transparent?: boolean;
}

export const HeaderDiv = styled.div`
background-color: #301a2a;

/*
@media (max-width: 1040px){
  font-size: 1.7rem
};

@media (max-width: 865px){
  font-size: 13px;
};

@media (max-width: 750px) {
  justify-content: space-between;
  };
*/
`;

export const TitlesDiv = styled.div`
padding-top: 2%;
padding-left:4%;
`;

export const Title = styled.h1`
font-family: "CenturyGothic";
color: #301a2a;
text-shadow: -0.7px 0.7px 0 #FFFF,
0.7px 0.7px 0 #FFFF,
0.7px -0.7px 0 #FFFF,
-0.7px -0.7px 0 #FFF;
font-weight: bold;
font-size: 10.5rem;

`;


export const FirstSubtitle = styled.h2`
font-family: "CenturyGothic";
color: white;
font-weight: bold;
font-size: 3.8rem;
line-height: 1.5;
`;


export const SecondSubtitle = styled.h3`
font-family: "CenturyGothic";
color: white;
font-size: 1.8rem;
width: 200px
`;

export const NameAndUserNameDiv = styled.div`
padding-top: 3%;
padding-left:4%;
padding-bottom:4%;
`;


export const Name = styled.h1`
font-family: "CenturyGothic";
color: white;
font-size: 8.5rem;
font-weight: bolder;
`;

export const Surname = styled.h1`
font-family: "CenturyGothic";
color: white;
font-size: 8.5rem;
`;
