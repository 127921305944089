import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../common/constants';

import {
  Screen,
} from '../Composh';



interface IProps {
  transparent?: boolean;
}


export const SixthSectionDiv = styled.div`
background-color: #000;
margin-top: 5%;
margin-bottom: 2%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const  DivContent = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding-bottom:2%;
width: 100%
`;



export const  DivPhotos = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 100%;
justify-content: space-between;
`;

export const  Photo = styled.img<{ $width?: string, $height?:string }>`
width: 400px;
height: 400px;
`;
