import React from 'react';
import { BsSpotify, BsTwitter, BsWhatsapp } from 'react-icons/bs';
import { FaDeezer, FaYoutube, FaApple, FaPlay, FaFacebook } from 'react-icons/fa';
import BigImage from '../BigImage';
import ImageRihanna from '../../assets/images/first-section-rihanna.jpg';
import { FirstSectionDiv, ContentDiv, TitlesAndMediasDiv, TitlesDiv, Title, Subtitle, MediasDiv, DivMedia, MediaTitle, MediaImages, DivSmallLogo, MediaText, DivBigImage } from './styled';

const Header: React.FC = () => {
  return (
<FirstSectionDiv>
<ContentDiv>
<TitlesAndMediasDiv>
<TitlesDiv>
        <Title>Lançamento</Title>
        <Subtitle>Chega mais</Subtitle>
    </TitlesDiv>
   
    <MediasDiv>
   
        <DivMedia>
        <MediaTitle>Ouça agora</MediaTitle>
        <MediaImages>
            <DivSmallLogo><BsSpotify color={'white'} size={'3rem'}/><MediaText>Spotify</MediaText></DivSmallLogo>
            <DivSmallLogo><FaDeezer color={'white'} size={'3rem'}/><MediaText>Deezer</MediaText></DivSmallLogo>
            <DivSmallLogo><FaYoutube color={'white'} size={'3rem'}/><MediaText>Music</MediaText></DivSmallLogo>
        </MediaImages>
        </DivMedia>
   
        <DivMedia>
        <MediaTitle>Compartilhe</MediaTitle>
        <MediaImages>
            <DivSmallLogo><FaFacebook color={'white'} size={'3rem'}/></DivSmallLogo>
            <DivSmallLogo><BsTwitter color={'white'} size={'3rem'}/></DivSmallLogo>
            <DivSmallLogo><BsWhatsapp color={'white'} size={'3rem'}/></DivSmallLogo>
        </MediaImages>
        </DivMedia>

        <DivMedia>
        <MediaTitle>Compre no</MediaTitle>
        <MediaImages>
        <DivSmallLogo><FaApple color={'white'} size={'3rem'}/><MediaText>iTunes</MediaText></DivSmallLogo>
            <DivSmallLogo><FaPlay color={'white'} size={'3rem'}/><MediaText>Google play</MediaText></DivSmallLogo>
        </MediaImages>
        </DivMedia>

        <DivMedia>
        <MediaTitle>Inscreva-se</MediaTitle>
        <MediaImages>
            <DivSmallLogo><FaYoutube color={'white'} size={'3rem'}/></DivSmallLogo>
        </MediaImages>
        </DivMedia>

    </MediasDiv>
    </TitlesAndMediasDiv>
    <DivBigImage>
    <BigImage srcImage={ImageRihanna} />
    </DivBigImage>
           
    </ContentDiv>
</FirstSectionDiv>
  );
};

export default Header;
