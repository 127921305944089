import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../common/constants';

import {
  Screen,
} from '../Composh';



interface IProps {
  transparent?: boolean;
}


export const SecondSectionDiv = styled.div<{ backgroundImage?: any }>`
background-image: url(${(props) => props.backgroundImage});
background-size: cover;
`;

export const  DivContent = styled.div`
background-color: rgba(37, 20, 33,0.9);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-bottom:2%;

`;


export const Title= styled.h1`
color: white;
font-size: 3rem;
margin-bottom: 3.2%;
margin-top: -1%;
font-weight: bold;
`;

export const DivImages= styled.div`
display: flex;
gap: 40px;
justify-content: space-evenly;
margin-bottom:2.5%;
`;

export const Image= styled.img`
width: 250px;
height: 400px;
over-flow: hidden;
border-radius: 130px
`;

