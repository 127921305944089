import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../common/constants';

import {
  Screen,
} from '../Composh';



interface IProps {
  transparent?: boolean;
}

export const ImageBorder = styled.div`
width: 90%;
height: 100%;
border: 1px solid #53005c;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding-top: 5%;
padding-bottom: 5%;
padding-left: 1%;
padding-right: 1%;
border-radius: 180px;
@media(max-width: 720px){
  width: 47%;
  padding-top: 3%;
padding-bottom: 3%;
}
@media(max-width: 600px){
  width: 51%;
}
@media(max-width: 530px){
  width: 55%;
}
@media(max-width: 470px){
  width: 70%;
  padding-top: 2%;
  padding-bottom: 2%;
}
@media(max-width: 450px){
  width: 75%;
}

`;

export const ImageBig = styled.img`
width: 90%;
height: 100%;
border-radius: 150px;

`;

