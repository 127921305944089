import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../common/constants';

import {
  Screen,
} from '../Composh';



interface IProps {
  transparent?: boolean;
}


export const ThirdSectionDiv = styled.div`
background-color: rgba(37, 20, 33,0.9);
margin-top: 2%;
margin-bottom: 2%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const  Title = styled.h1`
color: white;
font-size: 3rem;
margin-bottom: 3.2%;
margin-top: 3.2%;
font-weight: bold;
`;

export const  DivContent = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding-bottom:2%;
width: 80%
`;


export const DivEventItems= styled.div`
display: flex;
flex-direction: column;
gap: 50px;
`;
