import React from 'react';

import {
  Colors,
  Images,
} from '../../common/constants';

import {
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialTwitter,
} from '../../components/Icons';

import './styled.css';

// import { Container } from './styles';



const WaitingScreen: React.FC = () => {
  return (


    <body>

      <div className="main-area">

        <section className="left-section" style={{ backgroundImage: `url(${Images.persaWaiting})` }}>


        </section>


        <section className="right-section full-height">

          <div className="logo">
            <img
              src={Images.logoPersasNameWhite}
              alt="Logo"
            />
          </div>


          <div className="display-table">
            <div className="display-table-cell">
              <div className="main-content">
                <h1 className="title">
                  <b>Em breve!</b>
                </h1>

                <p className="desc">
                  Meu site ainda está em desenvolvimento, o melhor desenvolvimento - o meu.
                  Deve estar online em breve. Obrigado pela visita.
                </p>

                <div className="email-input-area">
                  <p className="post-desc">
                    Logo logo anuncio a data de lançamento! Só aguardar
                  </p>
                  {/* <form method="post">
                    <input className="email-input" name="email" type="text" placeholder="Enter your email" />
                    <button className="submit-btn" name="submit" type="submit"><b>NOTIFY US</b></button>
                  </form> */}
                </div>

              </div>
            </div>
          </div>



          <ul className="footer-icons">
            <li>
              Mantenha contato:
            </li>


            <li>
              <a href="#" className='ion-social-facebook'>
                <IconSocialFacebook
                  color={Colors.white}
                  size={18}
                />
              </a>
            </li>


            <li>
              <a href="#" className='ion-social-twitter'>
                <IconSocialTwitter
                  color={Colors.white}
                  size={18}
                />
              </a>
            </li>


            <li>
              <a href="#" className="ion-social-instagram-outline">
                <IconSocialInstagram
                  color={Colors.white}
                  size={18}
                />
              </a>
            </li>


            {/* <li>
              <a href="#" className="ion-social-googleplus">
                <i></i>
              </a>
            </li>


            <li>
              <a href="#">
                <i className="ion-social-pinterest"></i>
              </a>
            </li> */}

          </ul>

        </section>

      </div>

    </body>

  );
};



export default WaitingScreen;
