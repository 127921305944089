import React from 'react';

import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import HomeScreen from '../../screens/Home';
import WaitingScreen from '../../screens/Waiting';
import WelcomeScreen from '../../screens/Welcome';

import NameRoutes from '../names';



function Routes() {
  return (

    <BrowserRouter>
      <Switch>
      <Route exact path={NameRoutes.WaitingScreen} component={WaitingScreen} />
      <Route exact path={NameRoutes.HomeScreen} component={HomeScreen} />
        
        {/*
      
        <Route exact path={NameRoutes.WaitingScreen} component={WelcomeScreen} />
         */}

      </Switch>
    </BrowserRouter>

  );
}



export default Routes;
