import React from 'react';
import TShirtOne from '../../assets/images/fifth-section-tshirt-one.jpg';
import TShirtTwo from '../../assets/images/fifth-section-tshirt-two.jpg';
import TShirtThree from '../../assets/images/fifth-section-tshirt-three.jpg';
import TShirtFour from '../../assets/images/fifth-section-tshirt-four.jpg';
import { FifthSectionDiv, Title, Subtitle, DivContent, ShopItem, ShopImage, DivDescription, ItemName, ItemPrice } from './styled';
import ButtonPage from '../Button';

const tshirtItems: { key: number; imageSrc: string, itemName: string, itemPrice: number }[] = [
  { key: 1, imageSrc: TShirtOne, itemName: 'Anti T-Shirt', itemPrice: 50.00 },
  { key: 2, imageSrc: TShirtTwo, itemName: 'Anti T-Shirt', itemPrice: 50.00 },
  { key: 3, imageSrc: TShirtThree, itemName: 'Anti T-Shirt', itemPrice: 50.00 },
  { key: 4, imageSrc: TShirtFour, itemName: 'Anti T-Shirt', itemPrice: 50.00 },
];


const FifthSection: React.FC = () => {
  return (
    <FifthSectionDiv>
      <Title>Shop</Title>
      <Subtitle>Lorem Ipsum</Subtitle>
      <DivContent>
      {tshirtItems.map((tshirtItem) => (
          <ShopItem key={tshirtItem.key}>
          <ButtonPage buttonText='Shop Now'></ButtonPage>
          <ShopImage src = {tshirtItem.imageSrc} />
          <DivDescription>
            <ItemName>{tshirtItem.itemName}</ItemName>
            <ItemPrice>${tshirtItem.itemPrice.toFixed(2)}</ItemPrice>
          </DivDescription>
         </ShopItem>
      ))}
      </DivContent>
    </FifthSectionDiv>

  );
};


export default FifthSection;
