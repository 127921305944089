import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../common/constants';

import {
  Screen,
} from '../Composh';



interface IProps {
  transparent?: boolean;
}

export const FifthSectionDiv = styled.div`
background-color: rgba(37, 20, 33,0.9);
margin-top: 5%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-bottom: 5%;
padding-top: 2.5%;
`;

export const Title= styled.h1`
color: white;
font-size: 3rem;

font-weight: bold;
`;

export const Subtitle= styled.h1`
color: gray;
font-size: 1.5rem;
margin-bottom: 3.5%;
margin-top:1%;
font-weight: bold;
`;

export const  DivContent = styled.div`
display: flex;
flex-direction: row;
width:80%;
justify-content: space-between;
`;

export const  ShopItem = styled.div`
display: flex;
flex-direction:column;
justify-content: center;
align-items: center;

`;

export const  ShopImage = styled.img`
width: 300px;
height: 300px
`;

export const  DivDescription = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 padding-left: 10%;
 padding-right: 10%;
 width: 300px;
 height: 52px;
 border-right: 2px solid #af00dd;
 border-left: 2px solid #af00dd;
 
`;

export const  ItemName = styled.p`
color: white;
font-weight: bold;
font-size: 1.55rem;
`;

export const  ItemPrice  = styled.p`
color: white;
font-weight: bold;
font-size: 1.55rem;
`;
